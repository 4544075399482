import React from "react";
import { useQuery, useMutation } from "@apollo/client";

import moment from "moment";
import Privileges from "../../../Constants/Privilages";
import { dashboardCRMQuery } from "../../../Graphql/query";
import { removeActivityMutation } from "../../../Graphql/mutation";
import { dateFormat, dateTimeListFormat } from "../../../Constants";
import { openSwalConfirm, showToast } from "../../../Helpers/HelperFns";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../../Components/IconButtonWithTooltip";
import { BSelect } from "form-builder";
import { Link } from "react-router-dom";
import Loader from "../../../Components/Loader";
import ReactShowMoreText from "react-show-more-text";
import { DashboardList } from "../../../Components/CRM";
import { AddButton } from "../../../Components/Buttons";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ActivityModal } from "../../../Components/CRMFormModals";

const modalInitState = { isOpen: false, data: null };
const formatData = (data = []) => {
  const obj = {};

  for (const d of data) {
    const type = d.type ? JSON.parse(d.type)?.name : null;
    if (type) {
      const entry = {
        id: d.id,
        notes: d?.notes,
        leadID: d?.lead?.id,
        leadName: d?.lead?.name,
        email: d?.contact?.email,
        contact: d?.contact?.name,
        phone: d?.contact?.phones?.[0]?.[1],
        assignedTo: d?.lead?.assignedTo?.name,
        date: moment(d?.date).format(dateTimeListFormat),
      };

      obj[type] = obj[type] ? [...obj[type], entry] : [entry];
    }
  }

  return obj;
};

const ActivitiesDashboard = ({ options }) => {
  // Local State
  const [data, setData] = React.useState({});
  const [modalState, setModalState] = React.useState(modalInitState);
  const [filters, setFilters] = React.useState({ scope: "All", agent: null });

  // Server State
  const [removeActivity, { loading: removeLoading }] = useMutation(
    removeActivityMutation
  );
  const { loading: isLoading, refetch: refetchList } = useQuery(
    dashboardCRMQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        isActivities: true,
        activityScope: filters.scope,
        to: moment().format(dateFormat),
        from: moment().format(dateFormat),
        activityAgent: filters?.agent ? +filters?.agent?.id : undefined,
      },
      onCompleted: ({ activities = {} }) => {
        setData(formatData(activities?.data));
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  // Constants
  const columns = [
    { selector: "date" },
    { selector: "contact", style: { fontWeight: "bold" } },
    {
      cell: (row) => <Link to={`/crm/lead/${row.leadID}`}>{row.leadName}</Link>,
    },
    { selector: "email" },
    { selector: "phone" },
    { selector: "assignedTo" },
    {
      name: "notes",
      cell: ({ notes }) => (
        <ReactShowMoreText lines={1}>{notes}</ReactShowMoreText>
      ),
    },
    {
      cell: (data) => (
        <div className="d-flex gap-10">
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_ACTIVITIES,
            ]}
          >
            <EditIconButton onClick={() => handleOpenModal({ id: data.id })} />
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.DELETE_ACTIVITIES,
            ]}
          >
            <RemoveIconButton onClick={() => handleRemove(data)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleOpenModal = (data) => {
    setModalState({ isOpen: true, data });
  };

  const handleCloseModal = () => {
    setModalState(modalInitState);
  };

  const handleSetShowedScope = (_, scope) => {
    setFilters((prev) => ({ ...prev, scope }));
  };

  const handleRemove = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeActivity({
          variables: { id: data.id },
          onCompleted: () => {
            refetchList();
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ||
                err?.graphQLErrors[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  return (
    <div className="boxContainer py-2">
      {removeLoading ? <Loader fixed /> : null}

      {/* Header */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-20">
          <strong className="font-18 blue-color">Activities</strong>
          <AddButton isIconOnly onClick={() => handleOpenModal()} />
        </div>

        <div className="d-flex align-items-end gap-20">
          <BSelect
            isClearable
            name="agent"
            icon="employee"
            value={filters.agent}
            options={options.agents}
            rootStyle="min-width-200"
            placeholder="select agent"
            onChange={handleSelectFilters}
          />
          <ToggleButtonGroup
            exclusive
            size="small"
            color="primary"
            value={filters.scope}
            aria-label="Set showed scope"
            onChange={handleSetShowedScope}
          >
            <ToggleButton value="All" sx={{ textTransform: "capitalize" }}>
              All
            </ToggleButton>
            <ToggleButton value="Mine" sx={{ textTransform: "capitalize" }}>
              Mine
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      {/* List */}
      <DashboardList
        data={data}
        color="#23aaeb"
        columns={columns}
        isLoading={isLoading}
      />

      {/* Modal */}
      {modalState.isOpen ? (
        <ActivityModal
          data={modalState.data}
          refetchList={refetchList}
          onClose={handleCloseModal}
        />
      ) : null}
    </div>
  );
};

export default ActivitiesDashboard;
