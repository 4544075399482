import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import {
  showToast,
  formatOptions,
  openSwalConfirm,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import moment from "moment";
import Privileges from "../../Constants/Privilages";
import { dateTimeListFormat } from "../../Constants";
import { removeTrialMutation } from "../../Graphql/mutation";
import { trialsListOptionsQuery, trialsListQuery } from "../../Graphql/query";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import { FiltersChips } from "../../Components/CRM";
import Pagination from "../../Components/Pagination";
import FiltersDrawer from "../../Components/FiltersDrawer";
import { TrialModal } from "../../Components/CRMFormModals";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { AddButton, FilterButton } from "../../Components/Buttons";
import { InputForm, BSelect, DateTimePickerForm } from "form-builder";

const modalInitState = { isOpen: false, data: null };
const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const sendFilters = (filters) => {
  return {
    page: 1,
    ...formatSendingInputs(filters),
    company: filters?.company || undefined,
  };
};
const formatFiltersChips = (filters, others) => {
  return {
    ...filters,
    page: undefined,
    first: undefined,
    lead_ids: undefined,
    assigned_to: undefined,
    ...(others?.leadProfileData && { lead_ids: undefined }),
    ...(others?.isAdminProfile && { assigned_to: undefined }),
  };
};

const TrialsList = ({ isLeadProfile, leadProfileData, adminID }) => {
  const { t } = useTranslation();
  const isAdminProfile = Boolean(adminID);
  const filtersInitState = {
    company: "",
    status: null,
    user_id: null,
    to: undefined,
    from: undefined,
    assigned_to: isAdminProfile ? adminID : undefined,
    lead_ids: isLeadProfile ? [leadProfileData?.id] : undefined,
  };

  // Local State
  const [trials, setTrials] = useState([]);
  const [filtersChips, setFiltersChips] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [options, setOptions] = useState({ users: [] });
  const [filters, setFilters] = useState(filtersInitState);
  const [modalState, setModalState] = useState(modalInitState);
  const [pagination, setPagination] = useState(paginationInitState);
  const [disableSubmitFilters, setDisableSubmitFilters] = useState(true);

  // Server State
  const [removeTrial, { loading: removeLoading }] =
    useMutation(removeTrialMutation);
  const { loading: isOptionsLoading } = useQuery(trialsListOptionsQuery, {
    onCompleted: ({ users }) => {
      setOptions({ users: users?.data });
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors?.[0]?.extensions?.reason ||
          err?.graphQLErrors?.[0]?.message ||
          err?.message
      );
    },
  });
  const [getListData, { loading: isListLoading }] = useLazyQuery(
    trialsListQuery,
    {
      onCompleted: ({ trials }) => {
        setTrials(trials?.data);
        setPagination(trials?.paginatorInfo);
        setFiltersChips(
          formatFiltersChips(filters, { isAdminProfile, leadProfileData })
        );
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    }
  );

  // Constants
  const columns = [
    {
      name: t("company"),
      cell: ({ subscription = {}, lead }) => (
        <HasPrivileges
          reqireMain={[
            Privileges.SUPER_PRIVILEGE,
            Privileges.VIEW_LEAD_PROFILE,
          ]}
          altChildren={subscription?.company?.name}
        >
          <Link to={`/crm/lead/${lead?.id}`}>
            {subscription?.company?.name}
          </Link>
        </HasPrivileges>
      ),
    },
    {
      name: t("business partner"),
      cell: ({ subscription = {} }) =>
        subscription?.company?.businessPartners
          ?.map((b) => b?.user?.name)
          .join(", "),
    },
    {
      name: t("plan"),
      cell: ({ subscription = {} }) => subscription?.plan?.name,
    },
    {
      name: t("from"),
      cell: ({ subscription = {} }) =>
        moment(subscription?.start_date).format(dateTimeListFormat),
    },
    {
      name: t("to"),
      cell: ({ subscription = {} }) =>
        moment(subscription?.end_date).format(dateTimeListFormat),
    },
    { name: t("created by"), cell: ({ user = {} }) => user?.name },
    {
      name: t("primary account manager"),
      cell: ({ subscription = {} }) =>
        subscription?.company?.primarySAAS?.user?.name,
    },
    {
      name: t("secondary account managers"),
      cell: ({ subscription = {} }) =>
        subscription?.company?.secondarySAAS
          ?.map(({ user = {} }) => user?.name)
          .join(", "),
    },
    { name: t("status"), selector: "status" },
    {
      name: t("No. Of Employees"),
      selector: "subscription.limit",
    },
    {
      cell: (data) => (
        <>
          <HasPrivileges
            reqireMain={[
              Privileges.ADD_EDIT_TRIALS,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <EditIconButton
              onClick={() =>
                setModalState({ isOpen: true, data: { id: data.id } })
              }
            />
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[Privileges.DELETE_TRIALS, Privileges.SUPER_PRIVILEGE]}
          >
            <RemoveIconButton onClick={() => handleRemoveTrial(data)} />
          </HasPrivileges>
        </>
      ),
    },
  ];

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    refetchList();
  }, []);

  /* ↓ Helpers ↓ */

  const refetchList = (vars, variables) => {
    getListData({
      variables: {
        ...sendFilters(variables || filters),
        first: pagination.perPage,
        ...(isAdminProfile && { assigned_to: adminID }),
        ...(isAdminProfile && { lead_ids: [leadProfileData?.id] }),
        ...vars,
      },
    });
  };

  const closeDrawer = () => () => {
    setShowFilters(() => false);
    setDisableSubmitFilters(() => true);
  };

  const handleSubmitFilter = () => {
    refetchList();
    closeDrawer()();
  };

  const handleResetFilter = () => {
    handleFilter(filtersInitState);
  };

  const handleDeleteFilter = (key) => {
    const newFilters = { ...filters, [key]: undefined };
    setFilters(newFilters);
    refetchList({}, newFilters);
    setFiltersChips((prev) => ({ ...prev, [key]: undefined }));
  };

  const handlePaginate = (page = pagination.currentPage) => {
    refetchList({ page });
  };

  const handleFilter = (newFiltersState) => {
    setFilters(newFiltersState);
    setDisableSubmitFilters(false);
  };

  const handleTextFilters = (e) => {
    handleFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectFilters = (val, { name }) => {
    handleFilter((prev) => ({ ...prev, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    handleFilter((prev) => ({ ...prev, [name]: val }));
  };

  const handleRemoveTrial = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeTrial({
          variables: { id: data.id },
          onCompleted: () => {
            refetchList();
            showToast("success");
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  return (
    <>
      {removeLoading ? <Loader fixed /> : null}

      <div className="d-flex justify-content-between align-items-center pt-4 mb-4">
        {/* Actions */}
        <div className="d-flex justify-content-end gap-10 min-width-200">
          <FilterButton onClick={() => setShowFilters(true)} />
          <HasPrivileges
            reqireMain={[
              Privileges.ADD_EDIT_TRIALS,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <AddButton
              label="trial"
              onClick={() =>
                setModalState((prev) => ({ ...prev, isOpen: true }))
              }
            />
          </HasPrivileges>
        </div>

        {/* Filters Chips */}
        <div
          style={{ order: -1 }}
          className="d-flex flex-fill gap-10 flex-wrap"
        >
          <FiltersChips
            filters={filtersChips}
            handleDeleteFilter={handleDeleteFilter}
          />
        </div>
      </div>

      {/* Filters */}
      <FiltersDrawer
        skipCloseSubmit
        toggleDrawer={closeDrawer}
        isFilterDrawerOpen={showFilters}
        onFilterReset={handleResetFilter}
        onFilterSubmit={handleSubmitFilter}
        disableSubmitFilters={disableSubmitFilters}
        render={() => (
          <>
            <InputForm
              type="search"
              name="company"
              rootStyle="flex-fill"
              value={filters.company}
              onChange={handleTextFilters}
              inputContainerStyle="w-100"
              placeholder={t("search")}
            />
            <BSelect
              isClearable
              name="status"
              label="status"
              icon="employee"
              keepDefaultStyle
              containerStyle=" "
              rootStyle="flex-fill"
              value={filters.status}
              onChange={handleSelectFilters}
              options={formatOptions([
                "Upcoming",
                "Ongoing",
                "Expired",
                "Onboarded",
              ])}
            />
            <DateTimePickerForm
              hasIcon
              isClearable
              label="from"
              value={filters.from}
              rootStyle="flex-fill"
              containerStyle="py-0"
              labelStyle="w-100 mb-2"
              datePickerContainer="w-100"
              onChange={(val) => handleDateFiltersChange("from", val)}
            />
            <DateTimePickerForm
              hasIcon
              label="to"
              isClearable
              value={filters.to}
              rootStyle="flex-fill"
              containerStyle="py-0"
              labelStyle="w-100 mb-2"
              datePickerContainer="w-100"
              onChange={(val) => handleDateFiltersChange("to", val)}
            />
            <BSelect
              isClearable
              icon="employee"
              name="user_id"
              label="created by"
              keepDefaultStyle
              containerStyle=" "
              rootStyle="flex-fill"
              options={options.users}
              value={filters.user_id}
              onChange={handleSelectFilters}
              isLoading={isOptionsLoading}
            />
          </>
        )}
      />

      {/* List */}
      <DataTable
        noHeader
        data={trials}
        columns={columns}
        className="cards_table"
        progressPending={isListLoading}
        progressComponent={<Loader />}
        pagination
        paginationServer
        paginationComponent={() => (
          <Pagination
            styleWraper=""
            onPaginate={handlePaginate}
            customPaginator={pagination}
          />
        )}
      />

      {/* Modals */}
      {modalState.isOpen ? (
        <TrialModal
          data={modalState.data}
          refetchList={refetchList}
          isLeadProfile={isLeadProfile}
          leadProfileData={leadProfileData}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
    </>
  );
};

export default TrialsList;
