import React from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  revertReminderMutation,
  removeReminderMutation,
  completeReminderMutation,
} from "../../../Graphql/mutation";
import moment from "moment";
import Privileges from "../../../Constants/Privilages";
import { dashboardCRMQuery } from "../../../Graphql/query";
import { dateFormat, dateTimeListFormat } from "../../../Constants";
import { openSwalConfirm, showToast } from "../../../Helpers/HelperFns";

import {
  ToggleButton,
  FormControlLabel,
  ToggleButtonGroup,
} from "@mui/material";
import IconButtonWithTooltip, {
  EditIconButton,
  RemoveIconButton,
  AcceptIconButton,
} from "../../../Components/IconButtonWithTooltip";
import { BSelect } from "form-builder";
import { Link } from "react-router-dom";
import Loader from "../../../Components/Loader";
import UndoIcon from "@mui/icons-material/Undo";
import ReactShowMoreText from "react-show-more-text";
import AntSwitch from "../../../Components/AntSwitch";
import { DashboardList } from "../../../Components/CRM";
import { AddButton } from "../../../Components/Buttons";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";
import { ReminderModal } from "../../../Components/CRMFormModals";

let dataRef;
const modalInitState = { isOpen: false, data: null };
const formatData = (data = [], isShowCompleted) => {
  const obj = {};

  for (const d of data) {
    if (isShowCompleted) {
      if (d.status?.toLowerCase() !== "completed") continue;
    } else {
      if (d.status?.toLowerCase() === "completed") continue;
    }

    const type = d.type ? JSON.parse(d.type)?.name : null;
    if (type) {
      const entry = {
        id: d.id,
        notes: d?.notes,
        status: d?.status,
        leadID: d?.lead?.id,
        leadName: d?.lead?.name,
        email: d?.contact?.email,
        contact: d?.contact?.name,
        phone: d?.contact?.phones?.[0]?.[1],
        assignedTo: d?.lead?.assignedTo?.name,
        date: moment(d?.due_date).format(dateTimeListFormat),
      };

      obj[type] = obj[type] ? [...obj[type], entry] : [entry];
    }
  }

  return obj;
};

const RemindersDashboard = ({ options }) => {
  // Local State
  const [data, setData] = React.useState({});
  const [modalState, setModalState] = React.useState(modalInitState);
  const [filters, setFilters] = React.useState({
    agent: null,
    scope: "All",
    isShowCompleted: false,
  });

  // Server State
  const [removeReminder, { loading: removeLoading }] = useMutation(
    removeReminderMutation
  );
  const [revertReminder, { loading: revertLoading }] = useMutation(
    revertReminderMutation
  );
  const [completeReminder, { loading: completeLoading }] = useMutation(
    completeReminderMutation
  );
  const { loading: isLoading, refetch: refetchList } = useQuery(
    dashboardCRMQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        isReminders: true,
        remindersScope: filters.scope,
        to: moment().format(dateFormat),
        from: moment().format(dateFormat),
        reminderAgent: filters?.agent ? filters?.agent?.id : undefined,
      },
      onCompleted: ({ reminders = {} }) => {
        dataRef = reminders?.data;
        setData(formatData(reminders?.data, filters.isShowCompleted));
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  // Constants
  const columns = [
    { selector: "date" },
    { selector: "contact", style: { fontWeight: "bold" } },
    {
      cell: (row) => <Link to={`/crm/lead/${row.leadID}`}>{row.leadName}</Link>,
    },
    { selector: "email" },
    { selector: "phone" },
    { selector: "assignedTo" },
    {
      name: "notes",
      cell: ({ notes }) => (
        <ReactShowMoreText lines={1}>{notes}</ReactShowMoreText>
      ),
    },
    {
      cell: (data) => (
        <div className="d-flex gap-10">
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.SET_REMINDERS_AS_COMPLETED,
            ]}
          >
            {data.status.toLowerCase() !== "completed" ? (
              <AcceptIconButton
                label="complete"
                iconProps={{ color: "#23aaeb" }}
                onClick={() => handleCompleteReminder(data)}
              />
            ) : (
              <IconButtonWithTooltip
                label="revert"
                icon={<UndoIcon fontSize="small" />}
                onClick={() => handleRevertReminder(data)}
              />
            )}
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.ADD_EDIT_REMINDERS,
            ]}
          >
            <EditIconButton onClick={() => handleOpenModal({ id: data.id })} />
          </HasPrivileges>
          <HasPrivileges
            reqireMain={[
              Privileges.SUPER_PRIVILEGE,
              Privileges.DELETE_REMINDERS,
            ]}
          >
            <RemoveIconButton onClick={() => handleRemove(data)} />
          </HasPrivileges>
        </div>
      ),
    },
  ];

  /* ↓ Helpers ↓ */

  const handleSelectFilters = (val, { name }) => {
    setFilters((prev) => ({ ...prev, [name]: val }));
  };

  const handleOpenModal = (data) => {
    setModalState({ isOpen: true, data });
  };

  const handleCloseModal = () => {
    setModalState(modalInitState);
  };

  const handleSetShowedScope = (_, scope) => {
    setFilters((prev) => ({ ...prev, scope }));
  };

  const handleToggleShowCompleted = () => {
    setData(formatData(dataRef, !filters.isShowCompleted));
    setFilters((prev) => ({ ...prev, isShowCompleted: !prev.isShowCompleted }));
  };

  const handleRevertReminder = (data) => {
    const options = { text: "" };
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        revertReminder({
          variables: { id: data.id },
          onCompleted: () => {
            refetchList();
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ||
                err?.graphQLErrors[0]?.message ||
                err?.message
            );
          },
        });
      }
    }, options);
  };

  const handleCompleteReminder = (data) => {
    const options = { text: "" };
    openSwalConfirm((wilComplete) => {
      if (wilComplete) {
        completeReminder({
          variables: { id: data.id },
          onCompleted: () => {
            refetchList();
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ||
                err?.graphQLErrors[0]?.message ||
                err?.message
            );
          },
        });
      }
    }, options);
  };

  const handleRemove = (data) => {
    openSwalConfirm((willDelete) => {
      if (willDelete) {
        removeReminder({
          variables: { id: data.id },
          onCompleted: () => {
            refetchList();
          },
          onError: (err) => {
            showToast(
              "error",
              err?.graphQLErrors[0]?.extensions?.reason ||
                err?.graphQLErrors[0]?.message ||
                err?.message
            );
          },
        });
      }
    });
  };

  return (
    <div className="boxContainer py-2">
      {removeLoading || revertLoading || completeLoading ? (
        <Loader fixed />
      ) : null}

      {/* Header */}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-20">
          <strong className="font-18 blue-color">Reminders</strong>
          <AddButton isIconOnly onClick={() => handleOpenModal()} />
        </div>
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={filters.scope}
          aria-label="Set showed scope"
          onChange={handleSetShowedScope}
        >
          <ToggleButton value="All" sx={{ textTransform: "capitalize" }}>
            All
          </ToggleButton>
          <ToggleButton value="Mine" sx={{ textTransform: "capitalize" }}>
            Mine
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div className="d-flex align-items-center justify-content-between mt-2">
        <FormControlLabel
          labelPlacement="start"
          control={<AntSwitch />}
          label="show completed reminders"
          onChange={handleToggleShowCompleted}
          sx={{
            marginInlineStart: 0,
            ".MuiFormControlLabel-label": { fontSize: 14, marginInlineEnd: 2 },
          }}
        />
        <BSelect
          isClearable
          name="agent"
          icon="employee"
          value={filters.agent}
          options={options.agents}
          rootStyle="min-width-200"
          placeholder="select agent"
          onChange={handleSelectFilters}
        />
      </div>

      {/* List */}
      <DashboardList
        data={data}
        color="#ffb700"
        columns={columns}
        isLoading={isLoading}
      />

      {/* Modal */}
      {modalState.isOpen ? (
        <ReminderModal
          data={modalState.data}
          refetchList={refetchList}
          onClose={handleCloseModal}
        />
      ) : null}
    </div>
  );
};

export default RemindersDashboard;
