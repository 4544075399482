import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Loader from "../../Components/Loader";
import { integrateWithFoodicsMutation } from "../../Graphql/mutation";
import { showToast } from "../../Helpers/HelperFns";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const IntegrateWithFoodicsLoader = () => {
  const query = useQuery();
  const history = useHistory();

  // Constants
  const intgerationToken = query.get("code");

  console.log("aaaaaa", query);

  // handle integrate foodics
  useEffect(() => {
    if (intgerationToken) {
      handleIntegrateWithFoodics();
    }
    return () => {};
  }, []);

  /* ↓ Helpers ↓ */

  // Start of handle integrate with foodics mutation function
  const [integrateWithFoodics, { loading: integrationIsLoading }] = useMutation(
    integrateWithFoodicsMutation,
    {
      onCompleted: (res) => {
        if (res?.integrateWithFoodics?.id) {
          // navigate to foodics page
          history.push(`/foodics?tab=users`);

          // success toaster
          showToast("success");
        } else {
          // error toaster
          showToast(
            "error",
            res?.graphQLErrors[0]?.extensions?.reason ||
              res?.graphQLErrors[0]?.message ||
              res?.message
          );
        }
      },
      onError: (err) => {
        console.log({ err });
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    }
  );

  const handleIntegrateWithFoodics = () => {
    integrateWithFoodics({
      variables: {
        foodicsCode: intgerationToken,
      },
    });
  };
  // End of handle integrate with foodics mutation function

  return (
    <div className="content pt-3">
      {integrationIsLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
    </div>
  );
};

export default IntegrateWithFoodicsLoader;
