import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainModal from "../../../Components/MainModal";
import { InputForm, BSelect, RadioboxForm } from "form-builder";
import { useMutation } from "@apollo/client";
import { upsertCompanyAssetMutation } from "../../../Graphql/mutation";
import {
  onFormResetAction,
  toggleCompanyAssetModal,
  onInputChangeAction,
  addItemsInputAction,
  removeItemsInputAction,
  assetChildInputChange,
} from "../../../Store/Actions";
import { showToast } from "../../../Helpers/HelperFns";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const formName = "upsertCompanyAssetForm";
const formNameValidation = "upsertCompanyAssetFormClientValidations";

const UpsertCompanyAssetModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [serialNumberInputsValidation, setSerialNumberInputsValidation] =
    useState("");

  // Redux States
  const isOpen = useSelector(
    (state) => state?.assets?.companyAssetModal?.isOpen
  );
  const formData = useSelector((state) => state?.assets?.[formName]);
  const clientValidations = useSelector(
    (state) => state?.assets?.[formNameValidation]
  );

  // Server State
  useEffect(() => {
    return () => {
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction(formNameValidation));
    };
  }, []);

  // handle init object for items array when the product type is updated
  useEffect(() => {
    if (
      formData?.product_type === "serialized" &&
      formData?.items?.length < 1
    ) {
      dispatch(addItemsInputAction(formName));
    }
  }, [formData?.product_type]);

  // handle check the number of units is equal to number of serial numbers entered
  useEffect(() => {
    if (formData?.number_of_units < formData?.items?.length) {
      handleRemoveItemInput(formData?.items?.length - 1);
    }
  }, [formData?.number_of_units]);

  // Start of handle upsert company assets mutation
  const [upsertCompanyAsset, { loading }] = useMutation(
    upsertCompanyAssetMutation,
    {
      onCompleted: (data) => {
        if (data?.updateOrCreateAsset?.__typename == "GeneralException") {
          // error toaster
          showToast("error", data?.updateOrCreateAsset?.message);
          return;
        }
        // success toaster
        showToast("success");

        // handle dismiss modal
        handleDismissModal();

        // handle refetch data list
        props?.handleRefetch();
      },
      onError(err) {
        // error toaster
        showToast(
          "error",
          err?.graphQLErrors?.[0].extensions?.reason ?? err?.message
        );
      },
    }
  );
  // End of handle upsert company assets mutation

  /* ↓ Helpers ↓ */

  // handel text area change
  const handleTextArea = (e) => {
    dispatch(onInputChangeAction(formName, e));
  };

  // Start of add/remove items multiple inputs
  const handleAddItemInput = (e) => {
    if (
      e.key === "Enter" &&
      formData?.number_of_units > formData?.items?.length
    ) {
      dispatch(addItemsInputAction(formName));
    }
  };

  const handleRemoveItemInput = (index) => {
    dispatch(removeItemsInputAction(formName, index));
  };

  const handleChildInputChange = (event, childName, index) => {
    dispatch(
      assetChildInputChange(
        formName,
        childName,
        event?.target?.name,
        index,
        event?.target?.value
      )
    );
  };
  // End of add/remove items multiple inputs

  // handle submit form function
  const handleSubmit = () => {
    setFormSubmitting(true);
    setSerialNumberInputsValidation("");

    if (formData?.product_type === "non_serialized") {
      // handle unserliazed units array
      let unserliazedUnitsArray =
        formData?.id === null
          ? new Array(+formData?.number_of_units)
          : new Array(0);

      if (!clientValidations?.length) {
        upsertCompanyAsset({
          variables: {
            input:
              formData?.id === null
                ? {
                    id: formData?.id,
                    company_id: props?.companyId,
                    name: formData?.name,
                    brand: formData?.brand,
                    type_id: formData?.type_id,
                    model: formData?.model,
                    product_type: formData?.product_type,
                    items: unserliazedUnitsArray?.fill({
                      id: null,
                      serial_number: "",
                      warehouse_id: formData?.warehouse_id,
                      status: "in_storage",
                    }),
                    description: formData?.description,
                  }
                : {
                    id: formData?.id,
                    company_id: props?.companyId,
                    name: formData?.name,
                    brand: formData?.brand,
                    type_id: formData?.type_id,
                    model: formData?.model,
                    product_type: formData?.product_type,
                    description: formData?.description,
                  },
          },
        });
      }
    } else {
      // handle validation of serial number inputs
      if (formData?.number_of_units > formData?.items?.length) {
        setSerialNumberInputsValidation(
          "please enter serial numbers equal to number of units"
        );
      } else {
        if (!clientValidations?.length) {
          upsertCompanyAsset({
            variables: {
              input:
                formData?.id === null
                  ? {
                      id: formData?.id,
                      company_id: props?.companyId,
                      name: formData?.name,
                      brand: formData?.brand,
                      type_id: formData?.type_id,
                      model: formData?.model,
                      product_type: formData?.product_type,
                      items: formData?.items?.map(({ serial_number }) => ({
                        id: null,
                        serial_number,
                        warehouse_id: formData?.warehouse_id,
                        status: "in_storage",
                      })),
                      description: formData?.description,
                    }
                  : {
                      id: formData?.id,
                      company_id: props?.companyId,
                      name: formData?.name,
                      brand: formData?.brand,
                      type_id: formData?.type_id,
                      model: formData?.model,
                      product_type: formData?.product_type,
                      description: formData?.description,
                    },
            },
          });
        }
      }
    }
  };

  // handle dismiss modal function
  const handleDismissModal = () => {
    dispatch(toggleCompanyAssetModal({ isOpen: false }));
  };

  return (
    <MainModal
      isOpen={isOpen}
      toggle={handleDismissModal}
      modalTitle={formData?.id ? t("edit asset") : t("new asset")}
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <div className="row">
        <div className="col-12">
          <InputForm
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            reducer="assets"
            validateBy="textRequired"
            name="name"
            value={formData?.name?.trimStart()}
            placeholder={t("Enter Asset Name")}
            label="name"
            labelStyle="mb-2"
            containerStyle="mt-0 mb-3"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <BSelect
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            reducer="assets"
            name="type_id"
            label="type"
            keepDefaultStyle
            placeholder={t("select option")}
            validateBy="textRequired"
            options={props?.assetTypesOptions}
            optionLabel="name"
            optionValue="id"
            labelStyle="mb-1"
            inputContainerStyle="b-select-style "
            skipLocalization
            isClearable
          />
        </div>

        <div className="col-12 col-md-6">
          <InputForm
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            reducer="assets"
            validateBy="textRequired"
            name="brand"
            placeholder={t("Enter Asset Brand")}
            label="Brand"
            labelStyle="mb-2"
            containerStyle="mt-0 mb-3"
          />
        </div>
      </div>

      <div className="row">
        <div className={formData?.id === null ? "col-12 col-md-6" : "col-12"}>
          <InputForm
            formName={formName}
            formNameValidation={formNameValidation}
            formSubmitting={formSubmitting}
            reducer="assets"
            validateBy="textRequired"
            name="model"
            placeholder={t("Enter Asset Model")}
            label="Model"
            labelStyle="mb-2"
            containerStyle="mt-0 mb-3"
          />
        </div>

        {formData?.id === null ? (
          <div className="col-12 col-md-6">
            <InputForm
              formName={formName}
              formNameValidation={formNameValidation}
              formSubmitting={formSubmitting}
              reducer="assets"
              type="number"
              min="1"
              name="number_of_units"
              value={
                formData?.number_of_units < 1 &&
                formData?.number_of_units !== ""
                  ? 1
                  : formData?.number_of_units
              }
              placeholder={t("Enter No of Units")}
              label="No. of units"
              labelStyle="mb-2"
              containerStyle="mt-0 mb-3"
            />
          </div>
        ) : null}
      </div>

      {formData?.id === null ? (
        <>
          <div className="row">
            <div className="col-12">
              <RadioboxForm
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={formSubmitting}
                reducer="assets"
                name="product_type"
                label="Product Type"
                keepDefaultStyle
                options={[
                  { label: "Serialized", value: "serialized" },
                  { label: "Non-serialized", value: "non_serialized" },
                ]}
                containerStyle=""
                labelStyle="label-style"
                optionItemStyle="mr-5"
                optionInputStyle=" "
                optionLabelStyle="optionLabelStyle"
                optionsContainerStyle=""
                skipLocalization
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <BSelect
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={formSubmitting}
                reducer="assets"
                name="warehouse_id"
                label="Storage Locations"
                keepDefaultStyle
                placeholder={t("select option")}
                options={props?.storageLocationOptions}
                optionLabel="name"
                optionValue="id"
                labelStyle="mb-1"
                inputContainerStyle="b-select-style "
                skipLocalization
                isClearable
              />
            </div>
          </div>

          {formData?.product_type === "serialized" &&
          formData?.number_of_units ? (
            <div className="row my-3">
              <div className="col-12">
                <label className="mb-2">{t("Serial Numbers")}</label>

                <div className="serail_numbers_wrapper_style">
                  {formData?.items?.map((item, i) => (
                    <>
                      <div className="d-flex">
                        <InputForm
                          formName={formName}
                          formNameValidation={formNameValidation}
                          formSubmitting={formSubmitting}
                          onKeyPress={(e) => handleAddItemInput(e)}
                          reducer="assets"
                          name="serial_number"
                          value={item?.serial_number}
                          onChange={(e) =>
                            handleChildInputChange(e, "items", i)
                          }
                          labelStyle=""
                          containerStyle="mt-0"
                          inputContainerStyle=" "
                        />

                        {formData?.items?.length > 1 ? (
                          <>
                            {item?.serial_number ? (
                              <button
                                className="btn delete_btn_style mx-2"
                                type="button"
                                onClick={() => handleRemoveItemInput(i)}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </>
                  ))}
                </div>
              </div>

              {/* (Start) Error Message */}
              {serialNumberInputsValidation && (
                <div className="warnig-msg-style px-3 pt-1">
                  {serialNumberInputsValidation}
                </div>
              )}
              {/* (End) Error Message */}
            </div>
          ) : null}

          <div className="row my-2">
            <div className="col-12">
              <label className="mb-2">{t("Description")}</label>
              <textarea
                rows="4"
                formName={formName}
                formNameValidation={formNameValidation}
                formSubmitting={formSubmitting}
                name="description"
                onChange={handleTextArea}
                value={formData?.description}
              />
            </div>
          </div>
        </>
      ) : null}
    </MainModal>
  );
};

export default UpsertCompanyAssetModal;
