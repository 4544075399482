import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";

import {
  assignLeadsMutation,
  unassignLeadsMutation,
} from "../../Graphql/mutation";
import { showToast } from "../../Helpers/HelperFns";
import { setLeadsAgentFormQuery } from "../../Graphql/query";

import MainModal from "../MainModal";
import { LeadsServerRestSelect } from "../CRM";
import { RadioboxForm, BSelect } from "form-builder";

const operations = { ASSIGN: "ASSIGN", UNASSIGN: "UNASSIGN" };
const formNameValidation = "setLeadsAgentClientValidation";

const SetLeadsAgentModal = ({
  data,
  onClose,
  refetchList,
  onResetSelectedRows,
}) => {
  const { t } = useTranslation();

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [options, setOptions] = useState({ agents: [] });
  const [formData, setFormData] = useState({
    agent: null,
    operation: operations.ASSIGN,
    leads: data?.selectedRows.length ? data?.selectedRows : [],
  });

  // Server State
  const [assign, { loading: isAssignLoading }] =
    useMutation(assignLeadsMutation);
  const [unassign, { loading: isUnassignLoading }] = useMutation(
    unassignLeadsMutation
  );
  const { loading: isOptionsLoading } = useQuery(setLeadsAgentFormQuery, {
    onCompleted: ({ users }) => {
      setOptions({
        agents: users?.data || [],
      });
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Reducer State
  const assignLeadsClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );

  /* ↓ State Effects ↓ */

  /* ↓ Helpers ↓ */

  const handleSelectChange = (val, { action, name, ...rest }) => {
    if (action === "clear") {
      setFormData((prev) => ({ ...prev, [name]: [] }));
    } else {
      const isRemove = action.includes("remove");
      setFormData((prev) => ({
        ...prev,
        [name]: isRemove
          ? prev[name].filter((p) => p.id !== rest.removedValue.id)
          : val,
      }));
    }
  };

  const handleRadioChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (assignLeadsClientValidation.length) return;
    const isAssignOperation = formData.operation === operations.ASSIGN;
    const upsert = isAssignOperation ? assign : unassign;

    upsert({
      variables: {
        lead_ids: formData.leads.map(({ id }) => +id),
        user_id: isAssignOperation ? +formData.agent.id : undefined,
      },
      onCompleted: () => {
        refetchList();
        onResetSelectedRows();
        onClose();
        showToast("success");
      },
      onError: (error) => {
        showToast(
          "error",
          error?.graphQLErrors?.[0]?.extensions?.reason ||
            error?.graphQLErrors?.[0]?.message ||
            error?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      btnSubmitLoading={isAssignLoading || isUnassignLoading}
      modalTitle={t("set leads agent")}
    >
      <RadioboxForm
        name="operation"
        label="operation"
        value={formData.operation}
        onChange={handleRadioChange}
        options={[
          { label: "ASSIGN", value: operations.ASSIGN },
          { label: "UNASSIGN", value: operations.UNASSIGN },
        ]}
        optionInputStyle=" "
        containerStyle="d-flex gap-20"
        optionItemStyle="d-inline-block mx-2"
        optionsContainerStyle="d-flex"
        rootStyle="mb-3"
      />

      {formData.operation === operations.ASSIGN ? (
        <BSelect
          name="agent"
          icon="employee"
          label="assign to"
          value={formData.agent}
          onChange={handleSelectChange}
          options={options.agents}
          isLoading={isOptionsLoading}
          rootStyle="mb-4"
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
      ) : null}
      <LeadsServerRestSelect
        isMulti
        type="Leads"
        name="leads"
        label="leads"
        icon="employees"
        value={formData.leads}
        validateBy="textRequired"
        onChange={handleSelectChange}
        formSubmitting={formSubmitting}
        formNameValidation={formNameValidation}
      />
    </MainModal>
  );
};

export default SetLeadsAgentModal;
