import React from "react";
import { useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import Constants, {
  quartersOptions,
  crmYearsOptions,
  conditionalFooterRowStyles,
} from "../../../Constants";
import HelperFns, {
  showToast,
  getToDate,
  quarterToMonth,
} from "../../../Helpers/HelperFns";
import {
  GET_AGENT_PIPELINE_CUSTOM_REPORT,
  GET_AGENT_PIPELINE_MONTHLY_REPORT,
  GET_AGENT_PIPELINE_QUARTERLY_REPORT,
} from "../../../Graphql/query";
import Privileges from "../../../Constants/Privilages";
import { onFormResetAction } from "../../../Store/Actions";

import {
  LeadAgentSelect,
  CRMReportBarChart,
  CRMReportLinearChart,
} from "../../../Components/CRM";
import Loader from "../../../Components/Loader";
import DataTable from "react-data-table-component";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ViewRelevantLeads } from "../../../Components/CRMFormModals";
import { BSelect, RadioboxForm, DateTimePickerForm } from "form-builder";
import { BarsIconButton } from "../../../Components/IconButtonWithTooltip";

const modalInitState = { isOpen: false, data: null };
const filtersReducerKey = "agentPipelineReportFilters";
const formatData = (data) => {
  if (!data?.length) return data || [];

  const sum = data?.reduce(
    (acc, curr) => ({
      noExpand: true,
      Client: acc?.Client ? acc?.Client + +curr?.Client : +curr?.Client,
      Trial: acc?.Trial ? acc?.Trial + +curr?.Trial : +curr?.Trial,
      Demo: acc?.Demo ? acc?.Demo + +curr?.Demo : +curr?.Demo,
      new_users: acc?.new_users
        ? acc?.new_users + +curr?.new_users
        : +curr?.new_users,
    }),
    {}
  );

  return [...data, { name: "Total", ...sum }];
};
const getChartData = (data) => {
  const formattedData = data.reduce(
    (acc, curr, idx) => {
      if (idx === data?.length - 1) return acc;
      acc.agents.push(curr.Agent);
      acc.trialData.push(curr.Trial);
      acc.demoData.push(curr.Demo);
      acc.clientData.push(curr.Client);
      acc.newUsersData.push(curr.new_users);
      return acc;
    },
    {
      agents: [],
      trialData: [],
      demoData: [],
      clientData: [],
      newUsersData: [],
    }
  );

  return {
    labels: formattedData.agents,
    datasets: [
      { label: "Trial", data: formattedData.trialData },
      { label: "Demo", data: formattedData.demoData },
      { label: "Client", data: formattedData.clientData },
      { label: "New Users", data: formattedData.newUsersData },
    ],
  };
};

const AgentPipelineReport = () => {
  const dispatch = useDispatch();

  // Local State
  const [showChart, setShowChart] = React.useState(false);
  const [modalState, setModalState] = React.useState(modalInitState);

  // Reducer State
  const filters = useSelector((state) => state.crm[filtersReducerKey]);

  // Constants
  const canViewAll = HelperFns.checkPrivileges({
    privileges: [Privileges.VIEW_ALL_CRM_REPORTS],
  });

  /* ↓ Helpers ↓ */

  const renderFilters = () => {
    switch (filters.view) {
      case "monthly":
        return (
          <>
            <div className="d-flex align-items-center gap-10">
              <b>from</b>
              <BSelect
                name="fromMonth"
                value={filters.fromMonth}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="value"
              />
              <BSelect
                name="fromYear"
                value={filters.fromYear}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={crmYearsOptions}
                rootStyle="min-w-150"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                name="toMonth"
                value={filters.toMonth}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={Constants.MonthsData}
                rootStyle="min-w-150"
                optionLabel="key"
                optionValue="value"
              />
              <BSelect
                name="toYear"
                value={filters.toYear}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={crmYearsOptions}
                rootStyle="min-w-150"
              />
            </div>
          </>
        );

      case "quarterly":
        return (
          <>
            <div className="d-flex align-items-center gap-10">
              <b>from</b>
              <BSelect
                name="fromQuarter"
                value={filters.fromQuarter}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                name="fromYear"
                value={filters.fromYear}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={crmYearsOptions}
                rootStyle="min-w-150"
              />
            </div>
            <div className="d-flex align-items-center gap-10">
              <b>to</b>
              <BSelect
                name="toQuarter"
                value={filters.toQuarter}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={quartersOptions}
                rootStyle="min-w-150"
              />
              <BSelect
                name="toYear"
                value={filters.toYear}
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                options={crmYearsOptions}
                rootStyle="min-w-150"
              />
            </div>
          </>
        );

      case "custom":
        return (
          <>
            <DateTimePickerForm
              hasIcon
              label="from"
              placeholder="from"
              value={filters.from}
              datePickerContainer="w-100"
              labelStyle="font-weight-bold"
              containerStyle="py-0 d-flex gap-10 align-items-center"
              onChange={(val) => handleDateFiltersChange("from", val)}
            />
            <DateTimePickerForm
              hasIcon
              label="to"
              placeholder="to"
              value={filters.to}
              datePickerContainer="w-100"
              labelStyle="font-weight-bold"
              containerStyle="py-0 d-flex gap-10 align-items-center"
              onChange={(val) => handleDateFiltersChange("to", val)}
            />
          </>
        );

      default:
        break;
    }
  };

  const renderReports = () => {
    switch (filters.view) {
      case "monthly":
        return (
          <MonthlyReport
            filters={filters}
            showChart={showChart}
            openRelevantLeadsModal={openRelevantLeadsModal}
          />
        );

      case "quarterly":
        return (
          <QuarterlyReport
            filters={filters}
            showChart={showChart}
            openRelevantLeadsModal={openRelevantLeadsModal}
          />
        );

      case "custom":
        return (
          <CustomReport
            filters={filters}
            showChart={showChart}
            openRelevantLeadsModal={openRelevantLeadsModal}
          />
        );

      default:
        break;
    }
  };

  const openRelevantLeadsModal = (ids) => {
    const leadIDs = ids?.split(",");
    setModalState({ isOpen: true, data: { leadIDs } });
  };

  const handleRadioFilters = (event) => {
    dispatch(
      onFormResetAction(filtersReducerKey, {
        ...filters,
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleSelectFilters = (val, { name }) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };

  const handleDateFiltersChange = (name, val) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, [name]: val }));
  };

  const handleViewChange = (_, view) => {
    dispatch(onFormResetAction(filtersReducerKey, { ...filters, view }));
  };

  return (
    <>
      {/* Filters */}
      <div className="d-flex flex-wrap gap-20 align-items-center justify-content-between my-3">
        <ToggleButtonGroup
          exclusive
          size="small"
          color="primary"
          value={filters.view}
          onChange={handleViewChange}
          aria-label="Select View Mode"
        >
          <ToggleButton value="custom">custom</ToggleButton>
          <ToggleButton value="monthly">monthly</ToggleButton>
          <ToggleButton value="quarterly">quarterly</ToggleButton>
        </ToggleButtonGroup>

        <div className="flex-1 d-flex align-items-center gap-20 justify-content-end">
          {canViewAll ? (
            <div className="d-flex align-items-center gap-20">
              <RadioboxForm
                name="type"
                value={filters.type}
                optionInputStyle=" "
                containerStyle="mb-0"
                optionItemStyle="d-inline-block"
                optionsContainerStyle="d-flex gap-10"
                onChange={handleRadioFilters}
                options={[
                  { label: "active", value: "active" },
                  { label: "suspended", value: "suspend" },
                  { label: "all", value: "all" },
                ]}
              />
              <LeadAgentSelect
                isMulti
                name="agents"
                value={filters.agents}
                placeholder="select agents"
                onChange={handleSelectFilters}
                inputContainerStyle="w-100"
                rootStyle="min-w-150"
              />
            </div>
          ) : null}
          <div className="d-flex align-items-center gap-20">
            {renderFilters()}
            <BarsIconButton
              onClick={() => setShowChart((prev) => !prev)}
              label={showChart ? "show table" : "show chart"}
            />
          </div>
        </div>
      </div>

      {/* Charts and Tables */}
      {renderReports()}

      {/* Modals */}
      {modalState?.isOpen ? (
        <ViewRelevantLeads
          data={modalState.data}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
    </>
  );
};

export default AgentPipelineReport;

/**
 *
 * End of `Agent Pipeline Report`
 *
 * Start of `Monthly Report`
 *
 */

const MonthlyReport = ({ showChart, filters, openRelevantLeadsModal }) => {
  // Local State
  const [data, setData] = React.useState([]);

  // Server State
  const { loading } = useQuery(GET_AGENT_PIPELINE_MONTHLY_REPORT, {
    variables: {
      type: filters?.type,
      from: `${filters?.fromYear?.id}-${filters?.fromMonth?.key}-01`,
      to: getToDate(`${filters?.toYear?.id}-${filters?.toMonth?.key}`),
      agents: filters?.agents ? filters?.agents?.map((agent) => agent?.id) : [],
    },
    onCompleted: (res) => {
      setData(formatData(res?.agentPipelineMonthlyReport));
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      { name: "Agent", selector: "Agent" },
      {
        name: "New Clients",
        cell: (row) => (
          <ViewCell
            num={row?.Client}
            perc={row?.client_perc}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.clientLeads)}
          />
        ),
      },
      {
        name: "Trials",
        cell: (row) => (
          <ViewCell
            num={row?.Trial}
            perc={row?.trial_perc}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.trialLeads)}
          />
        ),
      },
      {
        name: "Demos",
        cell: (row) => (
          <ViewCell
            num={row?.Demo}
            perc={row?.demo_perc}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.demoLeads)}
          />
        ),
      },
      { name: "New Users", selector: "new_users", center: true },
    ],
    []
  );

  /* ↓ Helpers ↓ */

  return showChart ? (
    <CRMReportBarChart loading={loading} chartData={getChartData(data)} />
  ) : (
    <DataTable
      noHeader
      data={data}
      columns={columns}
      className="cards_table"
      progressPending={loading}
      progressComponent={<Loader />}
      expandableRows
      expandableRowsComponent={
        <ChildTable openRelevantLeadsModal={openRelevantLeadsModal} />
      }
      expandableRowDisabled={(row) => row?.noExpand}
      conditionalRowStyles={conditionalFooterRowStyles}
      customStyles={{
        rows: {
          style: {
            minHeight: "50px !important",
          },
        },
      }}
    />
  );
};

/**
 *
 * End of `Monthly Report`
 *
 * Start of `Quarterly Report`
 *
 */

const QuarterlyReport = ({ showChart, filters, openRelevantLeadsModal }) => {
  // Local State
  const [data, setData] = React.useState([]);

  // Server State
  const { loading } = useQuery(GET_AGENT_PIPELINE_QUARTERLY_REPORT, {
    variables: {
      type: filters?.type,
      agents: filters?.agents ? filters?.agents?.map((agent) => agent?.id) : [],
      from: `${filters?.fromYear?.id}-${quarterToMonth(
        filters?.fromQuarter?.id,
        "from"
      )}-01`,
      to: getToDate(
        `${filters?.toYear?.id}-${quarterToMonth(filters?.toQuarter?.id, "to")}`
      ),
    },
    onCompleted: (res) => {
      setData(formatData(res?.agentPipelineQuarterlyReport));
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      { name: "Agent", selector: "Agent" },
      {
        name: "New Clients",
        cell: (row) => (
          <ViewCell
            num={row?.Client}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.clientLeads)}
          />
        ),
      },
      {
        name: "Trials",
        cell: (row) => (
          <ViewCell
            num={row?.Trial}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.trialLeads)}
          />
        ),
      },
      {
        name: "Demos",
        cell: (row) => (
          <ViewCell
            num={row?.Demo}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.demoLeads)}
          />
        ),
      },
      { name: "New Users", selector: "new_users", center: true },
    ],
    []
  );

  return showChart ? (
    <CRMReportBarChart loading={loading} chartData={getChartData(data)} />
  ) : (
    <DataTable
      noHeader
      data={data}
      columns={columns}
      className="cards_table expandable"
      progressPending={loading}
      progressComponent={<Loader />}
      expandableRows
      expandableRowsComponent={
        <ChildTable
          isQuarterChild
          openRelevantLeadsModal={openRelevantLeadsModal}
        />
      }
      expandableRowDisabled={(row) => row?.noExpand}
      conditionalRowStyles={conditionalFooterRowStyles}
      customStyles={{
        rows: {
          style: {
            minHeight: "50px !important",
          },
        },
      }}
    />
  );
};

/**
 *
 * End of `Quarterly Report`
 *
 * Start of `Custom Report`
 *
 */

const getChildCustomChartData = (row, filters) => {
  return {
    labels: [
      filters?.from.format("YYYY-MM-DD"),
      filters?.to.format("YYYY-MM-DD"),
    ],
    datasets: [
      { label: "Trial", data: row?.Trial },
      { label: "Demo", data: row?.Demo },
      { label: "Client", data: row?.Client },
      { label: "New Users", data: row?.new_users },
    ],
  };
};

const CustomReport = ({ showChart, filters, openRelevantLeadsModal }) => {
  // Local State
  const [data, setData] = React.useState([]);

  // Server State
  const { loading } = useQuery(GET_AGENT_PIPELINE_CUSTOM_REPORT, {
    variables: {
      type: filters?.type,
      to: filters?.to.format("YYYY-MM-DD"),
      from: filters?.from.format("YYYY-MM-DD"),
      agents: filters?.agents ? filters?.agents?.map((agent) => agent?.id) : [],
    },
    onCompleted: (res) => {
      setData(res?.agentPipelineCustomReport || []);
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  // Constants
  const columns = React.useMemo(
    () => [
      { name: "Agent", selector: "Agent" },
      {
        name: "New Clients",
        cell: (row) => (
          <ViewCell
            num={row?.Client}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.clientLeads)}
          />
        ),
      },
      {
        name: "Trials",
        cell: (row) => (
          <ViewCell
            num={row?.Trial}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.trialLeads)}
          />
        ),
      },
      {
        name: "Demos",
        cell: (row) => (
          <ViewCell
            num={row?.Demo}
            isTotal={row?.noExpand}
            onClick={() => openRelevantLeadsModal(row?.demoLeads)}
          />
        ),
      },
      { name: "New Users", selector: "new_users", center: true },
    ],
    []
  );

  /* ↓ Helpers ↓ */

  const CustomChildExpander = (row) => (
    <CRMReportLinearChart
      child
      chartData={getChildCustomChartData(row?.data, filters)}
    />
  );

  return showChart ? (
    <CRMReportBarChart loading={loading} chartData={getChartData(data)} />
  ) : (
    <DataTable
      noHeader
      data={data}
      columns={columns}
      className="cards_table"
      progressPending={loading}
      progressComponent={<Loader />}
      // expandableRows
      expandableRowsComponent={<CustomChildExpander />}
    />
  );
};

/**
 *
 * End of `Custom Report`
 *
 * Start of `ViewCell`
 *
 */

export const ViewCell = ({ num, perc, onClick, isTotal }) => {
  const canView = HelperFns.checkPrivileges({
    privileges: [Privileges.VIEW_LEADS, Privileges.SUPER_PRIVILEGE],
  });
  const perc_num = perc?.slice(0, -1);
  const isClickable = canView && !isTotal && +num && onClick;

  return (
    <Box
      className="d-flex gap-10"
      {...(isClickable && {
        onClick,
        sx: {
          "&:hover": {
            cursor: "pointer",
            ".num": {
              color: "#23aaeb",
              textDecoration: "underline",
            },
          },
        },
      })}
    >
      <b className="num" style={{ width: 30, textAlign: "center" }}>
        {num}
      </b>
      <span
        className={perc_num > 0 ? "green-color" : "red-color"}
        style={{ minWidth: 60, textAlign: "center" }}
      >
        {perc ? `(${perc})` : null}
      </span>
    </Box>
  );
};

/**
 *
 * End of `ViewCell`
 *
 * Start of `ChildTable`
 *
 */

const getChildChartData = (data, isQuarterChild) => {
  const formattedData = data.reduce(
    (acc, curr) => {
      acc.labels.push(curr?.[isQuarterChild ? "Quarter" : "Month"]);
      acc.trialData.push(curr.Trial);
      acc.demoData.push(curr.Demo);
      acc.clientData.push(curr.Client);
      acc.newUsersData.push(curr.new_users);
      return acc;
    },
    {
      labels: [],
      trialData: [],
      demoData: [],
      clientData: [],
      newUsersData: [],
    }
  );

  return {
    labels: formattedData.labels,
    datasets: [
      { label: "Trial", data: formattedData.trialData },
      { label: "Demo", data: formattedData.demoData },
      { label: "Client", data: formattedData.clientData },
      { label: "New Users", data: formattedData.newUsersData },
    ],
  };
};

const ChildTable = ({
  filters,
  isQuarterChild,
  openRelevantLeadsModal,
  ...props
}) => {
  // Local State
  const [showChart, setShowChart] = React.useState(false);

  // Constants
  const data = props?.data?.[isQuarterChild ? "quarters" : "months"] || [];
  const columns = React.useMemo(
    () => [
      { width: "50px" },
      { selector: isQuarterChild ? "Quarter" : "Month" },
      {
        name: "New Clients",
        cell: (row) => (
          <ViewCell
            num={row?.Client}
            perc={row?.client_perc}
            onClick={() => openRelevantLeadsModal(row?.clientLeads)}
          />
        ),
      },
      {
        name: "Trials",
        cell: (row) => (
          <ViewCell
            num={row?.Trial}
            perc={row?.trial_perc}
            onClick={() => openRelevantLeadsModal(row?.trialLeads)}
          />
        ),
      },
      {
        name: "Demos",
        cell: (row) => (
          <ViewCell
            num={row?.Demo}
            perc={row?.demo_perc}
            onClick={() => openRelevantLeadsModal(row?.demoLeads)}
          />
        ),
      },
      { name: "New Users", selector: "new_users", center: true },
    ],
    []
  );

  return (
    <>
      <div className="d-flex justify-content-end mt-3 mb-4 px-3">
        <BarsIconButton
          onClick={() => setShowChart((prev) => !prev)}
          label={showChart ? "show table" : "show chart"}
        />
      </div>

      {showChart ? (
        <CRMReportLinearChart
          child
          chartData={getChildChartData(data, isQuarterChild)}
        />
      ) : (
        <DataTable
          noHeader
          noTableHead
          data={data}
          columns={columns}
          className="cards_table"
          customStyles={{
            rows: {
              style: {
                minHeight: "35px  !important",
                marginTop: "0 !important",
                border: "none !important",
                boxShadow: "none !important",
              },
            },
          }}
        />
      )}
    </>
  );
};
