import React from "react";
import { useQuery } from "@apollo/client";

import Privileges from "../../../Constants/Privilages";
import { dashboardCRMOptionsQuery } from "../../../Graphql/query";

import RemindersDashboard from "./RemindersDashboard";
import ActivitiesDashboard from "./ActivitiesDashboard";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";

const Dashboard = () => {
  // Local State
  const [options, setOptions] = React.useState({ agents: [] });

  // Server State
  const {} = useQuery(dashboardCRMOptionsQuery, {
    onCompleted: (res) => {
      setOptions((prev) => ({
        ...prev,
        agents: res?.agents?.data || [],
      }));
    },
  });

  return (
    <>
      <HasPrivileges
        reqireMain={[Privileges.VIEW_REMINDERS, Privileges.SUPER_PRIVILEGE]}
      >
        <RemindersDashboard options={options} />
      </HasPrivileges>
      <HasPrivileges
        reqireMain={[Privileges.VIEW_ACTIVITIES, Privileges.SUPER_PRIVILEGE]}
      >
        <ActivitiesDashboard options={options} />
      </HasPrivileges>
    </>
  );
};

export default Dashboard;
