import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";

import {
  buildOption,
  formatOptions,
  openSwalConfirm,
  formatSendingInputs,
} from "../../Helpers/HelperFns";
import {
  showToast,
  setCRMValidations,
  clearCRMValidations,
} from "../../Store/Actions";
import Privilages from "../../Constants/Privilages";
import { leadSourcesOptions } from "../../Constants";
import { getCitiesCountriesQuery, leadFormQuery } from "../../Graphql/query";
import { changeLeadMutation, storeLeadMutation } from "../../Graphql/mutation";

import Loader from "../Loader";
import Box from "@mui/material/Box";
import MainModal from "../MainModal";
import { AddButton } from "../Buttons";
import LeadsTagsModal from "./LeadsTagsModal";
import IntlTelInput from "react-intl-tel-input";
import CRMTagsSelect from "../CRM/CRMTagsSelect";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { InputForm, BSelect, BCRSelect } from "form-builder";
import { EditIconButton, RemoveIconButton } from "../IconButtonWithTooltip";

const phoneInitState = ["", "", "", ""]; // [dialCode, phone, iso2(countryCode), description]
const contactInitState = {
  id: null,
  name: "",
  email: "",
  position: "",
  phones: [phoneInitState],
};

const formNameValidation = "leadClientValidation";
const formServerValidation = "leadServerValidation";

const LeadModal = ({ data, onClose, refetchList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isEdit = Boolean(data);

  // Local State
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [tagsModal, setTagsModal] = useState({
    isOpen: false,
    data: null,
  });
  const [isFetchingInputDepend, setIsFetchingInputDepend] = useState(isEdit);
  const [options, setOptions] = useState({
    tags: [],
    users: [],
    cities: [],
    campaigns: [],
    countries: [],
    industries: [],
  });
  const [formData, setFormData] = useState({
    id: null,
    name: "",
    notes: "",
    tags: [],
    city: null,
    source: null,
    eps_no: null,
    campaign: null,
    industry: null,
    assigned_to: null,
    phones: [phoneInitState],
    contacts: [contactInitState],
    country: buildOption("Egypt"),
  });

  // Reducer State
  const leadClientValidation = useSelector(
    (state) => state.crm[formNameValidation]
  );
  const leadServerValidation = useSelector(
    (state) => state.crm[formServerValidation]
  );

  // Server State
  const [storeLead, { loading: isStoreLoading }] =
    useMutation(storeLeadMutation);
  const [changeLead, { loading: isChangeLoading }] =
    useMutation(changeLeadMutation);
  const { loading: isDataLoading, refetch: refetchOptions } = useQuery(
    leadFormQuery,
    {
      notifyOnNetworkStatusChange: true,
      variables: { id: data?.id, isLeadSkip: !isEdit },
      onCompleted: ({
        lead,
        users,
        tags = [],
        campaigns = [],
        countries = [],
        industries = [],
      }) => {
        // Data
        if (isEdit) {
          const {
            tags = [],
            contacts = [],
            city,
            country,
            source,
            assignedTo,
            phones,
            industry,
            ...data
          } = lead;
          setFormData({
            ...data,
            tags,
            assigned_to: assignedTo,
            city: buildOption(city),
            source: buildOption(source?.[0]),
            country: buildOption(country),
            industry: buildOption(industry),
            campaign: buildOption(source?.[1], "label", "value"),
            phones: phones?.length ? phones : [contactInitState],
            contacts: contacts?.length ? contacts : [contactInitState],
          });
          setIsFetchingInputDepend(false);
        }

        // Options
        setOptions({
          tags: tags?.data || [],
          users: users?.data || [],
          countries: formatOptions(countries),
          industries: formatOptions(industries),
          campaigns: formatOptions(campaigns, "label", "value"),
        });
      },
      onError: (error) => {
        showToast(
          "error",
          error?.graphQLErrors?.[0]?.extensions?.reason ||
            error?.graphQLErrors?.[0]?.message ||
            error?.message
        );
      },
    }
  );
  const { loading: isCityLoading } = useQuery(getCitiesCountriesQuery, {
    skip: !formData.country?.name,
    variables: { country: formData.country?.name },
    onCompleted: ({ cities }) => {
      setTimeout(() => {
        setOptions((prev) => ({ ...prev, cities: formatOptions(cities) }));
      }, 500);
    },
    onError: (error) => {
      showToast(
        "error",
        error?.graphQLErrors?.[0]?.extensions?.reason ||
          error?.graphQLErrors?.[0]?.message ||
          error?.message
      );
    },
  });

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (formData.country?.name) {
      if (!isFetchingInputDepend) {
        setFormData((prev) => ({ ...prev, city: null }));
      }
    }
  }, [formData.country?.name]);

  React.useEffect(() => {
    return () => {
      dispatch(clearCRMValidations(formServerValidation));
    };
  }, []);

  /* ↓ Helpers ↓ */

  const handleTextChange = (e) => {
    const { name, value, type = "" } = e.target;
    const isNum = type === "number";

    setFormData((prev) => ({
      ...prev,
      [name]: isNum ? +value : value,
    }));
  };

  const handleContactChange = (e, contactIndex) => {
    const { name, value, type = "" } = e.target;
    const isNum = type === "number";

    setFormData((prev) => ({
      ...prev,
      contacts: prev?.contacts.map((c, contactIdx) =>
        contactIndex === contactIdx
          ? {
              ...c,
              [name]: isNum ? +value : value,
            }
          : c
      ),
    }));
  };

  const handlePhoneInputChange = (input, phoneIndex, contactIndex) => {
    if (input?.isPhone && isNaN(input?.num)) return;

    const isContact = Boolean(contactIndex + 1);
    const phonesData = isContact
      ? formData.contacts[contactIndex]?.phones
      : formData.phones;

    const phone = [
      input?.dialCode ?? phonesData[phoneIndex][0],
      input?.num ?? phonesData[phoneIndex][1],
      input?.iso2 ?? phonesData[phoneIndex][2],
      input?.desc ?? phonesData[phoneIndex][3],
    ];
    const phones = phonesData.map((p, i) => (i === phoneIndex ? phone : p));

    setFormData((prev) => ({
      ...prev,
      ...(isContact
        ? {
            contacts: prev.contacts.map((c, i) =>
              i === contactIndex ? { ...c, phones } : c
            ),
          }
        : {
            phones,
          }),
    }));
  };

  const handleSelectChange = (val, { name: key }) => {
    setFormData((prev) => ({ ...prev, [key]: val }));
  };

  const handleCreateCampaignOption = (val) => {
    const newOpt = buildOption(val, "label", "value");
    setOptions((prev) => ({
      ...prev,
      campaigns: [...prev?.campaigns, newOpt],
    }));
    setFormData((prev) => ({ ...prev, campaign: newOpt }));
  };

  const handleAddPhone = () => {
    setFormData((prev) => ({
      ...prev,
      phones: [...prev.phones, phoneInitState],
    }));
  };

  const handleRemovePhone = (i) => {
    setFormData((prev) => ({
      ...prev,
      phones: prev.phones.filter((_, idx) => idx !== i),
    }));
  };

  const handleAddContact = () => {
    setFormData((prev) => ({
      ...prev,
      contacts: [...prev.contacts, contactInitState],
    }));
  };

  const handleRemoveContact = (i) => {
    setFormData((prev) => ({
      ...prev,
      contacts: prev.contacts.filter((_, idx) => idx !== i),
    }));
  };

  const handleAddContactPhone = (contactIdx) => {
    setFormData((prev) => ({
      ...prev,
      contacts: prev.contacts.map((c, i) =>
        i === contactIdx ? { ...c, phones: [...c.phones, phoneInitState] } : c
      ),
    }));
  };

  const handleRemoveContactPhone = (contactIdx, phoneIdx) => {
    setFormData((prev) => ({
      ...prev,
      contacts: prev.contacts.map((c, i) =>
        i === contactIdx
          ? {
              ...c,
              phones: c.phones.filter((_, i) => i !== phoneIdx),
            }
          : c
      ),
    }));
  };

  const updateTags = () => {
    refetchList();
    refetchOptions();
  };

  const handleUpsert = () => {
    setFormSubmitting(true);
    if (leadClientValidation.length) return;

    const upsert = !isEdit ? storeLead : changeLead;
    const { contacts, phones, name, source, campaign, tags, ...rest } =
      formData;

    const t = (confirm = 0) =>
      upsert({
        variables: {
          name,
          tagIds: !tags ? [] : tags?.map((t) => +t?.id),
          phones: phones.filter((phone) => phone[1]),
          source: source?.id
            ? [source?.id, ...(campaign ? [campaign?.value] : [])]
            : null,
          ...formatSendingInputs({ ...rest }),
          contacts: contacts.map(({ id, phones, ...contact }) => ({
            ...contact,
            confirm,
            id: id || undefined,
            lead_id: data?.id || undefined,
            phones: phones.filter((phone) => phone[1]),
          })),
        },
        onCompleted: () => {
          refetchList();
          onClose();
          showToast("success");
        },
        onError: (err) => {
          const validation = err?.graphQLErrors?.[0]?.extensions?.validation;
          if (validation) {
            dispatch(
              setCRMValidations({
                ref: formServerValidation,
                validations: validation,
              })
            );

            const contactValidationKeys = Object.keys(validation || {}).filter(
              (key) => /contacts.\d/.test(key)
            );

            if (
              validation?.contacts ||
              (contactValidationKeys.length &&
                validation[contactValidationKeys[0]][0]
                  .toLowerCase()
                  .includes("exist"))
            ) {
              const msg = validation[contactValidationKeys[0]][0]
                .toLowerCase()
                .includes("email")
                ? "This email already exists. Do you want to proceed?"
                : "This phone number already exists. Do you want to proceed?";
              openSwalConfirm((isConfirmed) => isConfirmed && t(1), {
                text: validation?.contacts || msg,
              });
            }
          }

          (err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message) &&
            showToast(
              "error",
              err?.graphQLErrors?.[0]?.extensions?.reason ||
                err?.graphQLErrors?.[0]?.message ||
                err?.message
            );
        },
      });

    t();
  };

  return (
    <MainModal
      isOpen
      toggle={onClose}
      btnOnClick={handleUpsert}
      btnSubmitLoading={isStoreLoading || isChangeLoading}
      modalTitle={t(`${isEdit ? "edit" : "new"} lead`)}
    >
      {isEdit && isDataLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <Box
        className="d-flex flex-wrap gap-10"
        sx={{ "& > .w-50": { width: "calc(50% - 5px) !important" } }}
      >
        <InputForm
          name="name"
          icon="employee"
          rootStyle="w-100"
          label="company name"
          placeholder={t("company name")}
          onChange={handleTextChange}
          inputContainerStyle="w-100"
          value={formData.name}
        />
        <InputForm
          name="notes"
          icon="type"
          rootStyle="w-100"
          label="notes"
          placeholder={t("notes")}
          onChange={handleTextChange}
          inputContainerStyle="w-100"
          value={formData.notes}
        />

        {/* Contacts */}
        <div className="w-100 d-flex align-items-center gap-20 my-3">
          <strong className="blue-color text-16">{t("contacts")}</strong>
          <AddButton isIconOnly onClick={handleAddContact} />
        </div>
        {formData.contacts.map((contact, i) => (
          <React.Fragment key={i}>
            <div className="w-100">
              <div className="d-flex align-items justify-content-between">
                {t("contact name")}
                {formData.contacts.length > 1 ? (
                  <RemoveIconButton onClick={() => handleRemoveContact(i)} />
                ) : null}
              </div>
              <InputForm
                name="name"
                icon="employee"
                labelStyle="w-100"
                inputContainerStyle="w-100"
                placeholder={t("contact name")}
                onChange={(e) => handleContactChange(e, i)}
                value={contact.name}
              />
            </div>

            {contact.phones.map((phone, idx) => (
              <div key={idx} className="w-100 d-flex gap-10">
                <div className="d-flex flex-fill gap-10">
                  <Box
                    className="w-50"
                    sx={{ ".intl-tel-input": { width: 1 } }}
                  >
                    <label
                      className={`d-block${
                        leadServerValidation[`contacts.${i}.phones`]
                          ? " red-color"
                          : ""
                      }`}
                    >
                      {t("phone")}
                    </label>
                    <IntlTelInput
                      value={phone?.[1] || ""}
                      separateDialCode
                      autoComplete="tel"
                      defaultCountry={phone?.[2] || "eg"}
                      inputClassName="input-style-default"
                      onSelectFlag={(num, { dialCode, iso2 }) =>
                        handlePhoneInputChange({ dialCode, num, iso2 }, idx, i)
                      }
                      onPhoneNumberChange={(_, num, { dialCode, iso2 }) =>
                        handlePhoneInputChange(
                          { dialCode, num, iso2, isPhone: true },
                          idx,
                          i
                        )
                      }
                    />
                    {leadServerValidation[`contacts.${i}.phones`] ? (
                      <p className="my-1 text-12 red-color">
                        {t(leadServerValidation[`contacts.${i}.phones`])}
                      </p>
                    ) : null}
                  </Box>
                  <InputForm
                    icon="phone"
                    rootStyle="w-50"
                    name="description"
                    label="description"
                    containerStyle="pb-0"
                    placeholder={t("whatsapp number")}
                    labelStyle="d-block"
                    value={phone[3]}
                    inputContainerStyle="w-100"
                    onChange={(e) =>
                      handlePhoneInputChange({ desc: e.target.value }, idx, i)
                    }
                  />
                </div>
                {idx ? (
                  <RemoveIconButton
                    onClick={() => handleRemoveContactPhone(i, idx)}
                  />
                ) : (
                  <AddButton
                    isIconOnly
                    onClick={() => handleAddContactPhone(i)}
                  />
                )}
              </div>
            ))}
            <InputForm
              icon="mail"
              name="email"
              label="email"
              placeholder={t("email")}
              rootStyle="w-50"
              labelStyle="w-100"
              value={contact.email}
              inputContainerStyle="w-100"
              onChange={(e) => handleContactChange(e, i)}
              formServerValidation={formServerValidation}
              validationName={`contacts.${i}.email`}
              formSubmitting={formSubmitting}
              reducer="crm"
            />
            <InputForm
              icon="office"
              name="position"
              label="position"
              placeholder={t("position")}
              rootStyle="w-50"
              labelStyle="w-100"
              value={contact.position}
              inputContainerStyle="w-100"
              onChange={(e) => handleContactChange(e, i)}
            />
            {formData.contacts.length > 1 && (
              <div className="w-100 my-2" aria-hidden="true" />
            )}
          </React.Fragment>
        ))}

        {/* Company Info */}
        <BSelect
          icon="city"
          name="country"
          label="country"
          placeholder={t("select country")}
          rootStyle="w-50"
          labelStyle="w-100"
          isLoading={isDataLoading}
          options={options?.countries}
          value={formData.country}
          inputContainerStyle="w-100"
          onChange={handleSelectChange}
          validateBy="textRequired"
          formSubmitting={formSubmitting}
          formNameValidation={formNameValidation}
        />
        <BSelect
          icon="city"
          name="city"
          label="city"
          placeholder={t("select city")}
          rootStyle="w-50"
          labelStyle="w-100"
          value={formData.city}
          options={options?.cities}
          isLoading={isCityLoading}
          inputContainerStyle="w-100"
          onChange={handleSelectChange}
          isDisabled={!Boolean(data || formData.country?.name)}
        />
        <InputForm
          type="number"
          name="eps_no"
          icon="employee"
          rootStyle="w-50"
          labelStyle="w-100"
          value={formData.eps_no}
          label="number of employees"
          placeholder={t("number of employees")}
          inputContainerStyle="w-100"
          onChange={handleTextChange}
        />
        <BSelect
          icon="city"
          name="industry"
          label="industry"
          placeholder={t("select industry")}
          rootStyle="w-50"
          labelStyle="w-100"
          isLoading={isDataLoading}
          options={options?.industries}
          value={formData.industry}
          inputContainerStyle="w-100"
          onChange={handleSelectChange}
        />
        <InputForm
          icon="globe"
          name="website"
          label="website"
          placeholder={t("website")}
          rootStyle="w-50"
          labelStyle="w-100"
          value={formData.website}
          inputContainerStyle="w-100"
          onChange={handleTextChange}
        />
        <BSelect
          icon="globe"
          name="source"
          label="source"
          rootStyle="w-50"
          labelStyle="w-100"
          value={formData.source}
          onChange={handleSelectChange}
          options={formatOptions(leadSourcesOptions)}
          inputContainerStyle="w-100"
        />
        {formData.source?.id === "Facebook" ? (
          <BCRSelect
            isClearable
            keepDefaultStyle
            name="campaign"
            label="campaign"
            rootStyle="w-100"
            isLoading={isDataLoading}
            value={formData?.campaign}
            options={options?.campaigns}
            onChange={handleSelectChange}
            classNamePrefix="b-select-style"
            onCreateOption={handleCreateCampaignOption}
          />
        ) : null}

        {/* Company Numbers */}
        <div className="w-100 d-flex align-items-center gap-20 my-3">
          <strong className="text-16">{t("company phone numbers")}</strong>
          <AddButton isIconOnly onClick={handleAddPhone} />
        </div>
        {formData.phones.map((phone, i) => (
          <Box
            key={i}
            sx={{ "& > .w-50": { width: "calc(50% - 5px) !important" } }}
            className="w-100 d-flex justify-content-between align-items-end gap-10"
          >
            <Box className="flex-fill" sx={{ ".intl-tel-input": { width: 1 } }}>
              <label className="d-block">{t("phone")}</label>
              <IntlTelInput
                value={phone?.[1] || ""}
                separateDialCode
                autoComplete="tel"
                defaultCountry={phone?.[2] || "eg"}
                inputClassName="input-style-default"
                onSelectFlag={(num, { dialCode, iso2 }) =>
                  handlePhoneInputChange({ dialCode, num, iso2 }, i)
                }
                onPhoneNumberChange={(_, num, { dialCode, iso2 }) =>
                  handlePhoneInputChange(
                    { dialCode, num, iso2, isPhone: true },
                    i
                  )
                }
              />
            </Box>
            <InputForm
              icon="phone"
              rootStyle="w-50"
              name="description"
              label="description"
              containerStyle="pb-0"
              placeholder={t("whatsapp number")}
              labelStyle="w-100"
              value={phone[3]}
              inputContainerStyle="w-100"
              onChange={(e) =>
                handlePhoneInputChange({ desc: e.target.value }, i)
              }
            />
            {formData.phones.length > 1 ? (
              <RemoveIconButton onClick={() => handleRemovePhone(i)} />
            ) : null}
          </Box>
        ))}

        <HasPrivileges reqireMain={[Privilages.ASSIGN_LEADS_TO_OTHER_AGENTS]}>
          {/* Assign Lead */}
          <strong className="blue-color text-16 my-3 w-100">
            {t("assign lead")}
          </strong>
          <BSelect
            icon="employees"
            name="assigned_to"
            rootStyle="w-100"
            label="assigned to"
            value={formData.assigned_to}
            onChange={handleSelectChange}
            options={options?.users}
            isLoading={isDataLoading}
            inputContainerStyle="w-100"
            containerStyle=" "
          />
        </HasPrivileges>

        {/* Tags */}
        <div className="w-100 d-flex align-items-center gap-20 mt-3">
          <strong className="blue-color text-16">{t("Tags")}</strong>
          <AddButton
            isIconOnly
            onClick={() =>
              setTagsModal({ isOpen: true, data: { isEdit: false } })
            }
          />
          <EditIconButton
            disabled={isDataLoading}
            onClick={() =>
              setTagsModal({
                isOpen: true,
                data: { isEdit: true, tags: options?.tags || [] },
              })
            }
          />
        </div>
        <CRMTagsSelect
          isMulti
          name="tags"
          label="tags"
          rootStyle="w-100"
          value={formData.tags}
          onChange={handleSelectChange}
          options={options?.tags}
          isLoading={isDataLoading}
          inputContainerStyle="w-100"
          containerStyle=" "
        />
      </Box>

      {tagsModal?.isOpen ? (
        <LeadsTagsModal
          data={tagsModal?.data}
          updateTags={updateTags}
          onClose={() => setTagsModal({ isOpen: false, data: null })}
        />
      ) : null}
    </MainModal>
  );
};

export default LeadModal;
