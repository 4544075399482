import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CompanyRemindersList,
  CompanyActivitiesList,
  SubscriptionList,
} from "../../Components/CompanyProfileComponents";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";

const CompanyProfileTabs = (props) => {
  const [activeValue, setActiveValue] = useState(null);
  const { t } = useTranslation();

  // handle first tab render according to privilages
  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.SUPER_PRIVILEGE, Privilages.VIEW_SUBSCRIPTIONS],
        // allowBP: true,
      })
    ) {
      setActiveValue("subscriptions");
      return;
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.SUPER_PRIVILEGE,
          Privilages.VIEW_COMPANY_ACTIVITIES,
        ],
        // allowBP: true,
      })
    ) {
      setActiveValue("activities");
      return;
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [
          Privilages.SUPER_PRIVILEGE,
          Privilages.VIEW_COMPANY_REMINDERS,
        ],
        // allowBP: true,
      })
    ) {
      setActiveValue("reminders");
      return;
    }
    return null;
  };

  // handle tab values changes
  const handleChange = (event, newValue) => {
    setActiveValue(newValue);
  };

  // handle render selected tab function
  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "subscriptions":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.VIEW_SUBSCRIPTIONS,
            ]}
          >
            <SubscriptionList
              companyId={props?.companyId}
              reducer={props?.reducer}
              dataTableRef={props?.dataTableRef}
              onPaginate={props?.onPaginate}
              {...props.companyProfile}
            />
          </HasPrivileges>
        );
      // case "activities":
      //   return (
      //     <HasPrivileges
      //       reqireMain={[
      //         Privilages.SUPER_PRIVILEGE,
      //         Privilages.VIEW_COMPANY_ACTIVITIES,
      //       ]}
      //     >
      //       <CompanyActivitiesList />
      //     </HasPrivileges>
      //   );
      // case "reminders":
      //   return (
      //     <HasPrivileges
      //       reqireMain={[
      //         Privilages.SUPER_PRIVILEGE,
      //         Privilages.VIEW_COMPANY_REMINDERS,
      //       ]}
      //     >
      //       <CompanyRemindersList />
      //     </HasPrivileges>
      //   );
      case "activities":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.VIEW_COMPANY_ACTIVITIES,
            ]}
          >
            <CompanyActivitiesList />
          </HasPrivileges>
        );
      case "reminders":
        return (
          <HasPrivileges
            reqireMain={[
              Privilages.SUPER_PRIVILEGE,
              Privilages.VIEW_COMPANY_REMINDERS,
            ]}
          >
            <CompanyRemindersList />
          </HasPrivileges>
        );
      default:
        break;
    }
  };

  // handle render first tab on change of active value
  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  // tab values constant
  const tabValues = [
    {
      value: "subscriptions",
      label: "Subscriptions",
      privileges: [Privilages.SUPER_PRIVILEGE, Privilages.VIEW_SUBSCRIPTIONS],
    },
    {
      value: "activities",
      label: "Activities",
      privileges: [
        Privilages.SUPER_PRIVILEGE,
        Privilages.VIEW_COMPANY_ACTIVITIES,
      ],
    },
    {
      value: "reminders",
      label: "Reminders",
      privileges: [
        Privilages.SUPER_PRIVILEGE,
        Privilages.VIEW_COMPANY_REMINDERS,
      ],
    },
  ];

  return (
    <div className="my-2">
      <TabContext value={activeValue}>
        {/* Tabs */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            {tabValues?.map((v) => {
              return HelperFns.checkPrivileges({
                allowBP: true,
                privileges: [...v?.privileges],
              }) ? (
                <Tab key={v?.value} label={t(v?.label)} value={v?.value} />
              ) : null;
            })}
          </TabList>
        </Box>

        {/* Selected Tab */}
        <div className="pt-4">{renderSelectedTab()}</div>
      </TabContext>
    </div>
  );
};

export default CompanyProfileTabs;
